import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { TimesheetMember } from "./timesheet-details-member-row";
import { useToast } from "@teamrota/rota-design";

const TIMESHEET_DETAILS = gql`
  query TimesheetsDetails($id: ID!, $offset: Int!, $limit: Int!) {
    account {
      id
      timesheetDetails(id: $id, offset: $offset, limit: $limit) {
        id
        name
        memberAvatar
        roleName
        totalMinutes
        totalPay
        payRate
        status
        checkInTime
        checkOutTime
        status
        ratingOfMember
        memberId
        isNoShow
        isTurnedAway
      }
    }
  }
`;

interface Params {
  id: string;
}

export const useTimesheetDetails = ({ id }: Params) => {
  const { showToast } = useToast();
  const { data, loading, error, refetch } = useQuery(TIMESHEET_DETAILS, {
    fetchPolicy: "cache-and-network",
    variables: { id, offset: 0, limit: 100 },
    onError: error => {
      showToast(error.message, { severity: "error" });
    }
  });

  const timesheetMembers: TimesheetMember[] =
    data?.account?.timesheetDetails || [];

  return {
    timesheetMembers,
    loading,
    error,
    refetch
  };
};
