import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { TimesheetFragment } from "./fragments";
import { useToast } from "@teamrota/rota-design";

const TIMESHEET_APPROVE = gql`
  mutation approveTimesheets($timesheetIds: [ID]!) {
    approveTimesheets(timesheetIds: $timesheetIds) {
      ...TimesheetFragment
    }
  }
  ${TimesheetFragment}
`;

interface Params {
  onCompleted: () => void;
}

export const useApproveTimesheets = ({ onCompleted }: Params) => {
  const { showToast } = useToast();
  const [approve, { loading }] = useMutation(TIMESHEET_APPROVE, {
    onError: error => {
      showToast(error.message, { severity: "error" });
    },
    onCompleted: ({ approveTimesheets }) => {
      showToast(
        approveTimesheets.length === 1
          ? "1 Timesheet was approved"
          : `${approveTimesheets.length} Timesheets were approved`
      );
      onCompleted();
    }
  });

  const approveTimesheets = useCallback(
    async ({ timesheetIds }) => {
      await approve({ variables: { timesheetIds } });
    },
    [approve]
  );

  return {
    approveTimesheets,
    loading
  };
};
