import { get, capitalize, initial, last, isEqual, omit } from "lodash/fp";
import { ZonedDate } from "@teamrota/rota-common";
import Money from "~/src/utils/money";

import {
  formatFullName,
  camelCaseToNormalText,
  formatDateMonthTime,
  formatDateMonthYear
} from "~/src/utils/formatting";

export const getAuthor = log => {
  const accountUser = get("metadata.updatedValues.accountUser.new", log);
  if (accountUser) {
    return `${accountUser} via the Member App`;
  }
  if (log.member) {
    return `${formatFullName(log.member)}`;
  } else if (log.user) {
    return `${formatFullName(log.user)} from ${get(
      "user.account.accountName",
      log
    )}`;
  }
  return "by Rota Platform";
};

const typeMap = {
  CREATE: "Added",
  UPDATE: "Updated",
  DELETE: "Removed"
};

const subjectMap = {
  MEMBER: "member",
  MEMBER_ROLE: "role",
  MEMBER_DOCUMENT: "document",
  SHIFT: "shift",
  BOOKING: "booking",
  POLICY_BOOKING: "booking policy",
  POLICY_SHIFT: "shift policy",
  POLICY_CONNECTION: "account policy",
  ACCOUNT: "account"
};

const notifications = [
  "EMAIL_SENT",
  "EMAIL_FAILED",
  "TEXT_SENT",
  "TEXT_FAILED"
];

const customLogActionTypes = {
  ACCOUNT: {
    CREATE: "Created this account.",
    UPDATE: "Updated this account."
  },
  SHIFT: {
    CREATE: "Created this shift",
    UPDATE: "Updated this shift"
  }
};

export const formatAction = log => {
  const times = log.count && log.count !== 1 ? `${log.count} x ` : "";
  if (notifications.includes(log.actionType)) {
    return `${times}${capitalize(log.actionType).replace("_", " ")}`;
  }
  return `${times}${typeMap[log.actionType]} a ${subjectMap[log.logSubject]}`;
};

const removeDuplicates = logArray =>
  logArray.filter((v, i, a) => a.findIndex(t => t.name === v.name) === i);

const customFormatters = {
  birthDate: {
    label: () => "Date of birth",
    value: value => (value ? formatDateMonthYear(new ZonedDate(value)) : value)
  },
  bonus: {
    label: value =>
      `${get("new.period", value) || get("old.period", value)} ${get(
        "new.type",
        value
      ) || get("old.type", value)}`,
    value: value => {
      const val = get("amount", value);
      if (!val) return val;
      const amountText = val
        ? Money({
            amount: val
          }).toFormat()
        : val;
      const reason = get("reason", value);
      const reasonText = reason ? `for reason: ${reason}` : "";
      return `${amountText} ${reasonText}`;
    }
  },
  disability: {
    label: () => "Known medical issues",
    value: val => val
  },
  // hardcoded for member. expand when there are more cases
  metadata: {
    label: () => "Members area",
    value: val => get("membersArea", val)
  },
  shouldExpireAt: {
    label: () => "Expiry date",
    value: value => (value ? formatDateMonthYear(new ZonedDate(value)) : value)
  },
  startOnShift: {
    label: () => "Check-in time",
    value: value => (value ? formatDateMonthTime(new ZonedDate(value)) : value)
  },
  startOnShiftAt: {
    label: () => "Time checked in",
    value: value => (value ? formatDateMonthTime(new ZonedDate(value)) : value)
  },
  endOnShift: {
    label: () => "Check-out time",
    value: value => (value ? formatDateMonthTime(new ZonedDate(value)) : value)
  },
  endOnShiftAt: {
    label: () => "Time checked out",
    value: value => (value ? formatDateMonthTime(new ZonedDate(value)) : value)
  },
  startOnShiftManagerAt: {
    label: () => "Time checked in on Manager App",
    value: value => (value ? formatDateMonthTime(new ZonedDate(value)) : value)
  },
  endOnShiftManagerAt: {
    label: () => "Time checked out on Manager App",
    value: value => (value ? formatDateMonthTime(new ZonedDate(value)) : value)
  },
  feedbackLabelsForMember: {
    label: camelCaseToNormalText,
    value: val => {
      if (!val) return null;
      const array = JSON.parse(val);
      if (!array) return null;
      return array.join(", ");
    }
  },
  uniform: {
    label: () => "Uniform",
    value: val => val
  },
  roleRateTags: {
    label: () => "Role Tags",
    value: tags => {
      if (!tags.length) {
        return "empty";
      }
      const filteredTags = removeDuplicates(tags);
      return filteredTags.map(tag => tag.name).join(", ");
    }
  },
  tags: {
    label: () => "Tags",
    value: tags => {
      if (!tags.length) {
        return "empty";
      }
      return tags.join(", ");
    }
  },
  poolType: {
    label: () => "Relationship",
    value: poolType => poolType
  }
};

const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

const defaultFormat = {
  label: camelCaseToNormalText,
  value: val => {
    if (val === true) {
      return "YES";
    } else if (val === false) {
      return "NO";
    }

    if (val?.match?.(DATE_PATTERN)) {
      try {
        return formatDateMonthTime(new ZonedDate(val));
      } catch {
        // fall through
      }
    }

    return val;
  }
};

export const formatLog = (logItem, label) => {
  const format = customFormatters[label] || defaultFormat;
  return {
    label: label === "bonus" ? format.label(logItem) : format.label(label),
    newValue: format.value(logItem.new),
    oldValue: format.value(logItem.old)
  };
};

const areDatesWithin10Mins = (date1, date2) =>
  Math.abs(new ZonedDate(date1) - new ZonedDate(date2)) < 1000 * 60 * 10; // 10 mintues in ms

export const groupLogs = logs =>
  logs.reduce((acc, curr, index) => {
    if (index === 0) return [{ ...curr, count: 1 }];

    const prev = last(acc);

    if (
      isEqual(
        omit(["count", "id", "createdAt"], curr),
        omit(["count", "id", "createdAt"], prev)
      ) &&
      areDatesWithin10Mins(curr.createdAt, prev.createdAt)
    ) {
      return [...initial(acc), { ...prev, count: prev.count + 1 }];
    }

    return [...acc, { ...curr, count: 1 }];
  }, []);

export const getCustomLogActions = log =>
  get(`${log.logSubject}.${log.actionType}`, customLogActionTypes);
