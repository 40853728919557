import React from "react";
import moment from "moment-timezone";
import { Currency } from "@teamrota/rota-common/dist/currency-support/index";
import { TIMESHEET_MEMBER_STATUS } from "@teamrota/rota-common";

import {
  StyledPin,
  StyledPinSuccess,
  StyledPinWarn,
  StyledPinError
} from "./style";

export const currencySymbol = {
  [Currency.GBP]: "£",
  [Currency.EUR]: "€"
};

export const getMemberStatusType = (
  status: keyof typeof TIMESHEET_MEMBER_STATUS
) => {
  if (
    [
      TIMESHEET_MEMBER_STATUS.APPROVED,
      TIMESHEET_MEMBER_STATUS.FINISHED
    ].includes(status)
  )
    return "success";
  if (
    [TIMESHEET_MEMBER_STATUS.TBC, TIMESHEET_MEMBER_STATUS.STARTED].includes(
      status
    )
  )
    return "warning";
  return "error";
};

interface GetMemberPinIconParams {
  status: keyof typeof TIMESHEET_MEMBER_STATUS;
  isLateCheckIn: boolean;
  isLateCheckOut: boolean;
  isChecked?: boolean;
}

export const getMemberPinIcon = ({
  status,
  isLateCheckIn,
  isLateCheckOut,
  isChecked = false
}: GetMemberPinIconParams) => {
  if (isLateCheckIn || isLateCheckOut) return <StyledPinError />;
  if (
    [
      TIMESHEET_MEMBER_STATUS.APPROVED,
      TIMESHEET_MEMBER_STATUS.FINISHED
    ].includes(status)
  )
    return <StyledPinSuccess />;
  if ([TIMESHEET_MEMBER_STATUS.TBC].includes(status))
    return <StyledPinWarn isChecked={isChecked} />;
  if ([TIMESHEET_MEMBER_STATUS.STARTED].includes(status)) return <StyledPin />;
  return <StyledPinError />;
};

export const memberStatusTranslations = {
  [TIMESHEET_MEMBER_STATUS.APPROVED]: "Approved",
  [TIMESHEET_MEMBER_STATUS.FINISHED]: "Finished",
  [TIMESHEET_MEMBER_STATUS.LATE]: "Late",
  [TIMESHEET_MEMBER_STATUS.TBC]: "TBC",
  [TIMESHEET_MEMBER_STATUS.STARTED]: "Started"
};

export function convertMinutesToTimeString(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);
  return `${hours} h ${remainingMinutes} min`;
}

export function convertHoursToTimeString(hours: number): string {
  const minutes = hours * 60;
  return convertMinutesToTimeString(minutes);
}

export enum Cancellation {
  NO_SHOW = "isNoShow",
  TURNED_AWAY = "isTurnedAway",
  NONE = "none"
}

export const cancellationOptions = [
  { label: "N/A", value: Cancellation.NONE },
  { label: "No show", value: Cancellation.NO_SHOW },
  { label: "Turned away", value: Cancellation.TURNED_AWAY }
];

export const getCancellation = ({
  isNoShow,
  isTurnedAway
}: {
  isNoShow: boolean;
  isTurnedAway: boolean;
}) => {
  if (isNoShow) return Cancellation.NO_SHOW;
  if (isTurnedAway) return Cancellation.TURNED_AWAY;
  return Cancellation.NONE;
};

export const calculateCheckInOutTimes = (
  isPayCalculationOnCheckInOutTimes: boolean,
  {
    shiftStartTime,
    shiftEndTime
  }: { shiftStartTime: string; shiftEndTime: string },
  checkInTime: string | undefined,
  checkOutTime: string | undefined
) => {
  const checkIn = !isPayCalculationOnCheckInOutTimes
    ? moment(shiftStartTime).format("HH:mm")
    : checkInTime
    ? moment(checkInTime).format("HH:mm")
    : "--:--";

  const checkOut = !isPayCalculationOnCheckInOutTimes
    ? moment(shiftEndTime).format("HH:mm")
    : checkOutTime
    ? moment(checkOutTime).format("HH:mm")
    : "--:--";

  return { checkIn, checkOut };
};
