import React, { useState } from "react";
import moment from "moment-timezone";

import { ZonedDate } from "@teamrota/rota-common";
import { Role } from "@teamrota/authlib";
import { RotaCalendar, RotaDropdown } from "@teamrota/rota-design";

import {
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_LABELS,
  MAX_UPLOAD_SIZE_MB,
  RIGHT_TO_WORK_SUBTYPES,
  RIGHT_TO_WORK_SUBTYPES_LABELS
} from "~/src/consts";

import useAuth from "~/src/auth/hooks/use-auth";
import Loading from "~/src/components/loading";
import CustomDropzone from "~/src/components/drop-zone/drop-zone";

import ComplianceDocumentsTable from "./ComplianceDocumentsTable";
import {
  StyledGrid,
  Label,
  RedBox,
  FileNote,
  ToolTip,
  SubTitle,
  Title,
  StyledButton,
  StyledValidationError
} from "./styles";

const COMPLIANCE_DOCUMENT_TYPES = [
  DOCUMENT_TYPES.RIGHT_TO_WORK,
  DOCUMENT_TYPES.COSHH,
  DOCUMENT_TYPES.DBS_BASIC,
  DOCUMENT_TYPES.DBS_ENHANCED,
  DOCUMENT_TYPES.ENHANCED_CHILD_DBS,
  DOCUMENT_TYPES.FIRE_SAFETY,
  DOCUMENT_TYPES.HACCP,
  DOCUMENT_TYPES.REFERENCE,
  DOCUMENT_TYPES.REFERENCE_2,
  DOCUMENT_TYPES.HEALTH_SAFETY_LEVEL_1,
  DOCUMENT_TYPES.HEALTH_SAFETY_LEVEL_2,
  DOCUMENT_TYPES.HEALTH_SAFETY_LEVEL_3,
  DOCUMENT_TYPES.ALLERGEN_AWARENESS,
  DOCUMENT_TYPES.FLOW_TRAINING,
  DOCUMENT_TYPES.PROOF_OF_ADDRESS,
  DOCUMENT_TYPES.NATIONAL_INSURANCE,
  DOCUMENT_TYPES.COVID_VACCINE_PASSPORT,
  DOCUMENT_TYPES.CV,
  DOCUMENT_TYPES.TRADE_TEST,
  DOCUMENT_TYPES.OTHER,
  DOCUMENT_TYPES.DEED_OF_NAME_CHANGE,
  DOCUMENT_TYPES.MARRIAGE_CERTIFICATE,
  DOCUMENT_TYPES.UNIVERSITY_ENROLMENT_LETTER,
  DOCUMENT_TYPES.UNIVERSITY_TERM_DATES,
  DOCUMENT_TYPES.HOME_OFFICE_LETTER,
  DOCUMENT_TYPES.SPOUSAL_DOCUMENT,
  DOCUMENT_TYPES.BIRTH_CERTIFICATE,
  DOCUMENT_TYPES.FOOD_SAFETY_LEVEL_1,
  DOCUMENT_TYPES.FOOD_SAFETY_LEVEL_2,
  DOCUMENT_TYPES.FOOD_SAFETY_LEVEL_3,
  DOCUMENT_TYPES.BOOKING_CONTRACT,
  DOCUMENT_TYPES.MANUAL_HANDLING,
  DOCUMENT_TYPES.SLIPS_TRIPS_FALLS_AWARENESS,
  DOCUMENT_TYPES.APPLICATION_FORM,
  DOCUMENT_TYPES.BASIC_FIRST_AID,
  DOCUMENT_TYPES.BUCCAL,
  DOCUMENT_TYPES.CHILD_PROTECTION,
  DOCUMENT_TYPES.CLEARANCE,
  DOCUMENT_TYPES.CORU,
  DOCUMENT_TYPES.CORU_PIN_CHECK,
  DOCUMENT_TYPES.DRIVERS_LICENSE,
  DOCUMENT_TYPES.EVIDENCE_OF_PPS,
  DOCUMENT_TYPES.FIRE_SAFETY_AWARENESS_ONLINE,
  DOCUMENT_TYPES.GARDA_VETTING_FW,
  DOCUMENT_TYPES.GARDA_VETTING_NFW,
  DOCUMENT_TYPES.GARDA_VETTING_APPLICATION_FORM,
  DOCUMENT_TYPES.GDPR,
  DOCUMENT_TYPES.HAND_HYGIENE,
  DOCUMENT_TYPES.HEALTHIER_BUSINESS_FORM,
  DOCUMENT_TYPES.HEARTSAVER_AED_CPR,
  DOCUMENT_TYPES.INFECTION_PREVENTION_CONTROL,
  DOCUMENT_TYPES.INTERVIEW_NOTES,
  DOCUMENT_TYPES.IRISH_MEDICAL_COUNCIL,
  DOCUMENT_TYPES.IRISH_MEDICAL_COUNCIL_PIN_CHECK,
  DOCUMENT_TYPES.MAPA,
  DOCUMENT_TYPES.MEDICATION_MANAGEMENT,
  DOCUMENT_TYPES.NMBI_PIN_CHECK,
  DOCUMENT_TYPES.NMBI_REGISTRATION,
  DOCUMENT_TYPES.OCCUPATIONAL_FIRST_AID,
  DOCUMENT_TYPES.OCCUPATIONAL_HEALTH,
  DOCUMENT_TYPES.PASSPORT_PHOTO,
  DOCUMENT_TYPES.PATIENT_MOVING_HANDLING,
  DOCUMENT_TYPES.QC_NOTE,
  DOCUMENT_TYPES.QUALIFICATIONS,
  DOCUMENT_TYPES.REFERENCE_3,
  DOCUMENT_TYPES.RISK_ASSESSMENT,
  DOCUMENT_TYPES.SAFE_ADMINISTRATION_OF_MEDICATION,
  DOCUMENT_TYPES.SAFEGUARDING_OF_VULNERABLE_ADULTS,
  DOCUMENT_TYPES.SIGN_OFF,
  DOCUMENT_TYPES.SKYPE_SCREENING_CALL,
  DOCUMENT_TYPES.TCI,
  DOCUMENT_TYPES.VACCINATIONS,
  DOCUMENT_TYPES.VERIFICATION_OF_SERVICE
];

const NO_ISSUE_DATE_NEEDED = [
  DOCUMENT_TYPES.REFERENCE,
  DOCUMENT_TYPES.NATIONAL_INSURANCE,
  DOCUMENT_TYPES.CV,
  RIGHT_TO_WORK_SUBTYPES.STUDENT_VISA_10_HOURS,
  RIGHT_TO_WORK_SUBTYPES.STUDENT_VISA_20_HOURS,
  DOCUMENT_TYPES.TRADE_TEST,
  DOCUMENT_TYPES.OTHER,
  DOCUMENT_TYPES.DEED_OF_NAME_CHANGE,
  DOCUMENT_TYPES.MARRIAGE_CERTIFICATE,
  DOCUMENT_TYPES.UNIVERSITY_ENROLMENT_LETTER,
  DOCUMENT_TYPES.UNIVERSITY_TERM_DATES,
  DOCUMENT_TYPES.HOME_OFFICE_LETTER,
  DOCUMENT_TYPES.SPOUSAL_DOCUMENT,
  RIGHT_TO_WORK_SUBTYPES.EEA_SETTLEMENT_STATUS_REPORT,
  RIGHT_TO_WORK_SUBTYPES.EEA_SETTLEMENT_STATUS_REPORT_SETTLED,
  RIGHT_TO_WORK_SUBTYPES.PASSPORT_WITH_RIGHT_OF_RESIDENCE_EEA_NATIONAL_FAMILY_MEMBER,
  RIGHT_TO_WORK_SUBTYPES.PASSPORT_WITH_VISA_GIVING_INDEFINITE_LEAVE_TO_REMAIN,
  RIGHT_TO_WORK_SUBTYPES.PASSPORT_WITH_VISA_GIVING_LIMITED_LEAVE_TO_REMAIN,
  DOCUMENT_TYPES.COSHH,
  DOCUMENT_TYPES.FIRE_SAFETY,
  DOCUMENT_TYPES.HACCP,
  DOCUMENT_TYPES.SLIPS_TRIPS_FALLS_AWARENESS,
  DOCUMENT_TYPES.APPLICATION_FORM,
  DOCUMENT_TYPES.CLEARANCE,
  DOCUMENT_TYPES.CORU_PIN_CHECK,
  DOCUMENT_TYPES.EVIDENCE_OF_PPS,
  DOCUMENT_TYPES.GARDA_VETTING_APPLICATION_FORM,
  DOCUMENT_TYPES.HEALTHIER_BUSINESS_FORM,
  DOCUMENT_TYPES.INTERVIEW_NOTES,
  DOCUMENT_TYPES.IRISH_MEDICAL_COUNCIL_PIN_CHECK,
  DOCUMENT_TYPES.NMBI_PIN_CHECK,
  DOCUMENT_TYPES.PASSPORT_PHOTO,
  DOCUMENT_TYPES.QC_NOTE,
  DOCUMENT_TYPES.QUALIFICATIONS,
  DOCUMENT_TYPES.REFERENCE_3,
  DOCUMENT_TYPES.RISK_ASSESSMENT,
  DOCUMENT_TYPES.SIGN_OFF,
  DOCUMENT_TYPES.SKYPE_SCREENING_CALL,
  DOCUMENT_TYPES.VACCINATIONS,
  DOCUMENT_TYPES.VERIFICATION_OF_SERVICE
];

const NO_EXPIRY_DATE_NEEDED = [
  RIGHT_TO_WORK_SUBTYPES.FULL_BIRTH_CERTIFICATE,
  RIGHT_TO_WORK_SUBTYPES.CERTIFICATE_OF_NATURALISATION,
  RIGHT_TO_WORK_SUBTYPES.IMMIGRATION_STATUS_DOCUMENT,
  RIGHT_TO_WORK_SUBTYPES.EEA_SETTLEMENT_STATUS_REPORT_SETTLED,
  DOCUMENT_TYPES.REFERENCE,
  DOCUMENT_TYPES.FLOW_TRAINING,
  DOCUMENT_TYPES.PROOF_OF_ADDRESS,
  DOCUMENT_TYPES.NATIONAL_INSURANCE,
  DOCUMENT_TYPES.CV,
  DOCUMENT_TYPES.TRADE_TEST,
  DOCUMENT_TYPES.OTHER,
  DOCUMENT_TYPES.DEED_OF_NAME_CHANGE,
  DOCUMENT_TYPES.MARRIAGE_CERTIFICATE,
  DOCUMENT_TYPES.UNIVERSITY_ENROLMENT_LETTER,
  DOCUMENT_TYPES.UNIVERSITY_TERM_DATES,
  DOCUMENT_TYPES.HOME_OFFICE_LETTER,
  DOCUMENT_TYPES.SPOUSAL_DOCUMENT,
  DOCUMENT_TYPES.BIRTH_CERTIFICATE,
  DOCUMENT_TYPES.APPLICATION_FORM,
  DOCUMENT_TYPES.CLEARANCE,
  DOCUMENT_TYPES.CORU_PIN_CHECK,
  DOCUMENT_TYPES.EVIDENCE_OF_PPS,
  DOCUMENT_TYPES.GARDA_VETTING_APPLICATION_FORM,
  DOCUMENT_TYPES.HEALTHIER_BUSINESS_FORM,
  DOCUMENT_TYPES.INTERVIEW_NOTES,
  DOCUMENT_TYPES.IRISH_MEDICAL_COUNCIL_PIN_CHECK,
  DOCUMENT_TYPES.NMBI_PIN_CHECK,
  DOCUMENT_TYPES.PASSPORT_PHOTO,
  DOCUMENT_TYPES.QC_NOTE,
  DOCUMENT_TYPES.QUALIFICATIONS,
  DOCUMENT_TYPES.REFERENCE_3,
  DOCUMENT_TYPES.RISK_ASSESSMENT,
  DOCUMENT_TYPES.SIGN_OFF,
  DOCUMENT_TYPES.SKYPE_SCREENING_CALL,
  DOCUMENT_TYPES.VACCINATIONS,
  DOCUMENT_TYPES.VERIFICATION_OF_SERVICE
];

const ComplianceDocuments = ({
  handleUploadFile,
  complianceDocuments,
  handleDeleteDocument,
  uploadPercentage,
  setUploadPercentage
}) => {
  const [documentType, setDocumentType] = useState("");
  const [documentSubtype, setDocumentSubtype] = useState("");
  const [documentIssueDate, setDocumentIssueDate] = useState(new ZonedDate());
  const [documentExpiryDate, setDocumentExpiryDate] = useState(new ZonedDate());
  const [
    isDocumentExpiryDateDisabled,
    setIsDocumentExpiryDateDisabled
  ] = useState(true);
  const [
    isDocumentIssueDateDisabled,
    setIsDocumentIssueDateDisabled
  ] = useState(true);
  const [validationErrors, setValidationErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubtypeSelector, setShowSubtypeSelector] = useState(false);
  const [documentRef, setDocumentRef] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisplayDropzone, setIsDisplayDropzone] = useState(true);

  const auth = useAuth();
  const isAllowedToCreateMemberCompliance = auth.hasRole(
    Role.MEMBER_COMPLIANCE_CREATE
  );

  const resetUploader = () => {
    setDocumentType("");
    setDocumentSubtype("");

    setDocumentIssueDate(new ZonedDate());
    setDocumentExpiryDate(new ZonedDate());

    setIsDocumentIssueDateDisabled(true);
    setIsDocumentExpiryDateDisabled(true);
    setValidationErrors(null);

    // Reset Dropzone by flickering it in and out of React component tree
    setIsDisplayDropzone(false);
    setIsDisplayDropzone(true);
  };

  const validateInputData = () => {
    const validationErrors = {};
    let isValid = true;
    if (!documentType) {
      validationErrors.documentType = "Please select Document type.";
    }

    if (documentType === DOCUMENT_TYPES.RIGHT_TO_WORK && !documentSubtype) {
      validationErrors.documentSubtype = "Please select Category.";
      isValid = false;
    }

    if (
      ![documentType, documentSubtype].some(document =>
        NO_ISSUE_DATE_NEEDED.includes(document)
      ) &&
      moment(documentIssueDate).isAfter(new ZonedDate())
    ) {
      validationErrors.documentIssueDate = "Issue date can't be in future.";
      isValid = false;
    }

    if (
      ![documentType, documentSubtype].some(document =>
        NO_EXPIRY_DATE_NEEDED.includes(document)
      ) &&
      moment(documentExpiryDate).isBefore(new ZonedDate())
    ) {
      validationErrors.documentExpiryDate = "Expiry date can't be in past.";
      isValid = false;
    }

    if (!documentRef.current.files.length) {
      validationErrors.file = "Please select document for upload.";
      isValid = false;
    }

    return isValid ? true : !!setValidationErrors(validationErrors);
  };

  const handleUploadButton = async () => {
    setIsLoading(true);

    if (validateInputData()) {
      await handleUploadFile(
        documentType,
        "complianceDocument",
        documentSubtype,
        isDocumentIssueDateDisabled ? undefined : documentIssueDate,
        isDocumentExpiryDateDisabled ? undefined : documentExpiryDate
      );
      resetUploader();
      setUploadPercentage(0);
    }

    setIsLoading(false);
  };

  const handleDocumentTypeChange = selectedValue => {
    setDocumentType(selectedValue);
    setIsDocumentExpiryDateDisabled(
      NO_EXPIRY_DATE_NEEDED.includes(selectedValue)
    );
    setIsDocumentIssueDateDisabled(
      NO_ISSUE_DATE_NEEDED.includes(selectedValue)
    );
    if (selectedValue === DOCUMENT_TYPES.RIGHT_TO_WORK) {
      setShowSubtypeSelector(true);
    } else {
      setShowSubtypeSelector(false);
    }
    setDocumentSubtype("");
    setIsDisabled(false);
  };

  const handleDocumentSubtypeChange = selectedValue => {
    setDocumentSubtype(selectedValue);
    setIsDocumentExpiryDateDisabled(
      NO_EXPIRY_DATE_NEEDED.includes(selectedValue)
    );
    setIsDocumentIssueDateDisabled(
      NO_ISSUE_DATE_NEEDED.includes(selectedValue)
    );
  };

  COMPLIANCE_DOCUMENT_TYPES.sort((a, b) =>
    a === DOCUMENT_TYPES.RIGHT_TO_WORK
      ? -1
      : b === DOCUMENT_TYPES.RIGHT_TO_WORK
      ? 1
      : a?.localeCompare(b)
  );

  const formattedComplianceDocumentTypes = COMPLIANCE_DOCUMENT_TYPES.map(
    type => ({
      label: DOCUMENT_TYPES_LABELS[type],
      value: type
    })
  );

  const formattedComplianceDocumentSubTypes = Object.keys(
    RIGHT_TO_WORK_SUBTYPES
  ).map(subtype => ({
    label: RIGHT_TO_WORK_SUBTYPES_LABELS[subtype],
    value: subtype
  }));

  /**
   * @description this function helps to return a valid date whether a calender is used or form input is used by user.
   * It is useful when shouldNotAllowInput of RotaCalendar component is set to false
   * @param {Object} event event object from DOM
   * @returns date object
   */
  const documentUploadForm = (
    <>
      <StyledGrid item xs={5} isMarginTop isBorder>
        <Label>Document type</Label>

        <RotaDropdown
          label={""}
          options={formattedComplianceDocumentTypes}
          width={"100%"}
          errorMessage={validationErrors?.documentType}
          isError={!!validationErrors?.documentType}
          value={documentType}
          onChange={handleDocumentTypeChange}
        />
      </StyledGrid>
      <StyledGrid item xs={2} />
      {showSubtypeSelector && (
        <StyledGrid item xs={5} isMarginTop isBorder>
          <Label>Category</Label>

          <RotaDropdown
            label={""}
            options={formattedComplianceDocumentSubTypes}
            width={"100%"}
            errorMessage={validationErrors?.documentSubtype}
            isError={!!validationErrors?.documentSubtype}
            value={documentSubtype}
            onChange={handleDocumentSubtypeChange}
          />
        </StyledGrid>
      )}
      {!showSubtypeSelector && <StyledGrid item xs={5} />}

      <StyledGrid item xs={5} isMarginTop isBorder>
        <Label>Issue Date:</Label>
        <RedBox />

        <RotaCalendar
          onChange={({ date }) => setDocumentIssueDate(date)}
          selectedDate={documentIssueDate}
          maxDate={new ZonedDate()}
          disabled={isDocumentIssueDateDisabled}
          maxYear={2022}
        />

        <StyledValidationError>
          {validationErrors?.documentIssueDate}
        </StyledValidationError>
      </StyledGrid>
      <StyledGrid item xs={2} />
      <StyledGrid item xs={5} isMarginTop isBorder>
        <Label>Expiry Date:</Label>
        <RedBox />

        <RotaCalendar
          onChange={({ date }) => setDocumentExpiryDate(date)}
          selectedDate={documentExpiryDate}
          minDate={new ZonedDate()}
          shouldNotSelectPastDate={true}
          disabled={isDocumentExpiryDateDisabled}
          minYear={2000}
          maxYear={2035}
        />

        <StyledValidationError>
          {validationErrors?.documentExpiryDate}
        </StyledValidationError>
      </StyledGrid>

      <StyledGrid item xs={12} isMarginTop isBorder>
        {isDisplayDropzone && (
          <CustomDropzone
            id="complianceDocument"
            progress={uploadPercentage}
            isDisabled={isDisabled}
            setDocumentRef={setDocumentRef}
          />
        )}
        <FileNote>
          Max {MAX_UPLOAD_SIZE_MB} MB file size, formats accepted (jpeg, jpg,
          png, pdf)
        </FileNote>
      </StyledGrid>

      <StyledGrid item xs={5} isMarginTop isBorder>
        <StyledButton
          variant="contained"
          onClick={() => handleUploadButton()}
          disabled={isDisabled || isLoading}
        >
          {isLoading && <Loading color="black" />}
          {!isLoading && "UPLOAD"}
        </StyledButton>
      </StyledGrid>

      <StyledGrid item xs={7} />
    </>
  );

  return (
    <>
      <StyledGrid container>
        {(isAllowedToCreateMemberCompliance || complianceDocuments) && (
          <Title>Compliance Details</Title>
        )}
        {isAllowedToCreateMemberCompliance && (
          <>
            <StyledGrid item xs={12}>
              <SubTitle>Documentation Uploader</SubTitle>
              {showSubtypeSelector && (
                <ToolTip>
                  A member's right to work will lock based on the{" "}
                  <strong>soonest</strong> expiry date. Once this has expired,
                  you will need to upload a new right to work document before
                  re-activating the account.
                </ToolTip>
              )}
            </StyledGrid>
            {documentUploadForm}
          </>
        )}
        {complianceDocuments && (
          <StyledGrid item xs={12} isMarginTop isBorder>
            <ComplianceDocumentsTable
              complianceDocuments={complianceDocuments}
              deleteDocument={handleDeleteDocument}
            />
          </StyledGrid>
        )}
      </StyledGrid>
    </>
  );
};

export default ComplianceDocuments;
