import React, { useState } from "react";
import styled from "styled-components";
import {
  THead,
  TrHead,
  Th as BaseTh,
  TBody,
  InnerCell,
  RotaCheckbox,
  useSelectedItems,
  RotaPlaceholder,
  RotaBox,
  RotaButtonIcon,
  iconNew,
  useToast
} from "@teamrota/rota-design";

import RotaButtonNew from "@teamrota/rota-design/dist/components/button";
import { TIMESHEET_MEMBER_STATUS } from "@teamrota/rota-common";
import { TimesheetInternalStatus } from "./types";
import { useTimesheetDetails } from "./use-timesheet-details";
import {
  RotaTableScrollable,
  SelectedItemsInfo,
  ActionsWrapper
} from "./style";

import TimesheetBreakdownModal from "./timesheet-breakdown-modal";
import { Timesheet } from "./use-timesheets-with-stats";
import TimesheetDetailsMemberRow, {
  TimesheetDetailsMemberRowLoader
} from "./timesheet-details-member-row";
import { useApproveTimesheetMembers } from "./use-approve-timesheet-members";

const { ChevronLeft, Expand, Contract } = iconNew;

const Th = styled(BaseTh)`
  font-weight: 500;
  font-size: 16px;
  background-color: ${({ theme }) => theme.neutral.lighter};
`;

const Box = styled(RotaBox)`
  margin: 32px;
  width: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 16px;
  align-items: center;
  padding: 16px 0px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: calc(100% - 72px);
`;

const TimesheetDetailsLoader = () => {
  return (
    <Box>
      <HeaderWrapper>
        <RotaPlaceholder circle size={48} />
        <RotaPlaceholder size={24} maxWidth={180} />
        <RotaPlaceholder circle size={48} />
      </HeaderWrapper>
      <InnerWrapper>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox isChecked={false} onClick={() => {}} />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", width: 266 }}>Name</Th>
              <Th style={{ textAlign: "left", minWidth: 200 }}>Cancellation</Th>
              <Th>In/Out</Th>
              <Th>Total hours</Th>
              <Th>Status</Th>
            </TrHead>
          </THead>
          <TBody>
            {Array.from({ length: 8 }).map((_, index) => (
              <TimesheetDetailsMemberRowLoader key={index} />
            ))}
          </TBody>
        </RotaTableScrollable>
      </InnerWrapper>
    </Box>
  );
};

interface Props {
  timesheet: Timesheet;
  showSummary: boolean;
  onClose: () => void;
  onToggleExpand: () => void;
}

export const TimesheetDetails = ({
  timesheet,
  showSummary,
  onClose,
  onToggleExpand
}: Props) => {
  const { showToast } = useToast();
  const { timesheetMembers, loading, refetch } = useTimesheetDetails({
    id: timesheet.id
  });
  const [openBreakdownModal, setOpenBreakdownModal] = useState(false);

  const {
    selectedItemsArray,
    hasSelectedAll,
    onToggleOneItem,
    onToggleAllItems,
    reset
  } = useSelectedItems(
    timesheetMembers.reduce<string[]>((acc, timesheetMember) => {
      if ([TIMESHEET_MEMBER_STATUS.APPROVED].includes(timesheetMember.status))
        return acc;
      return [...acc, timesheetMember.memberId];
    }, [])
  );

  const { approveTimesheetMembers } = useApproveTimesheetMembers({
    onCompleted: approvedCount => {
      showToast(
        `${approvedCount} ${
          approvedCount === 1 ? "Member" : "Members"
        } approved`
      );
      reset();
    }
  });

  if (loading) return <TimesheetDetailsLoader />;
  if (!timesheetMembers) return null;

  return (
    <>
      <Box>
        <HeaderWrapper>
          <RotaButtonIcon onClick={onClose}>
            <ChevronLeft />
          </RotaButtonIcon>
          <Title>
            {timesheet.shiftName || `Shift at ${timesheet.venueName}`}
          </Title>
          <RotaButtonIcon onClick={onToggleExpand}>
            {showSummary ? <Expand /> : <Contract />}
          </RotaButtonIcon>
        </HeaderWrapper>
        <ActionsWrapper>
          <ButtonsWrapper>
            <RotaButtonNew
              disabled={
                [
                  TimesheetInternalStatus.LOCKED,
                  TimesheetInternalStatus.APPROVED
                ].includes(timesheet.status) || !selectedItemsArray.length
              }
              onClick={() => {
                approveTimesheetMembers({
                  memberIds: selectedItemsArray,
                  timesheetId: timesheet.id
                });
              }}
            >
              Approve
            </RotaButtonNew>

            <RotaButtonNew onClick={() => setOpenBreakdownModal(true)}>
              Breakdown
            </RotaButtonNew>
          </ButtonsWrapper>
          {!!selectedItemsArray.length && (
            <SelectedItemsInfo>{`Selected ${selectedItemsArray.length} timesheets`}</SelectedItemsInfo>
          )}
        </ActionsWrapper>

        <InnerWrapper>
          <RotaTableScrollable>
            <THead>
              <TrHead>
                <Th style={{ width: 60 }}>
                  <InnerCell>
                    <RotaCheckbox
                      isDisabled={[
                        TimesheetInternalStatus.LOCKED,
                        TimesheetInternalStatus.APPROVED
                      ].includes(timesheet.status)}
                      isChecked={hasSelectedAll}
                      onClick={() => onToggleAllItems()}
                    />
                  </InnerCell>
                </Th>
                <Th />
                <Th style={{ textAlign: "left", width: 266 }}>Name</Th>
                <Th style={{ textAlign: "left", minWidth: 200 }}>
                  Cancellation
                </Th>
                <Th>In/Out</Th>
                <Th>Total hours</Th>
                <Th>Status</Th>
              </TrHead>
            </THead>
            <TBody>
              {timesheetMembers.map(timesheetMember => {
                return (
                  <TimesheetDetailsMemberRow
                    timesheet={timesheet}
                    refetchTimesheetDetails={refetch}
                    timesheetMember={timesheetMember}
                    isChecked={selectedItemsArray.includes(
                      timesheetMember.memberId
                    )}
                    onToggleOneItem={onToggleOneItem}
                  />
                );
              })}
            </TBody>
          </RotaTableScrollable>
        </InnerWrapper>
      </Box>
      {openBreakdownModal && (
        <TimesheetBreakdownModal
          timesheet={timesheet}
          onClose={() => setOpenBreakdownModal(false)}
        />
      )}
    </>
  );
};

export default TimesheetDetails;
