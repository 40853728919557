import React from "react";
import styled from "styled-components";

interface MemberListPopupProps {
  members: string[];
}

const PopupContainer = styled.div`
  position: absolute;
  left: 110%;
  top: -5px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000;
  padding: 8px;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
`;

const MemberList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const MemberItem = styled.li`
  padding: 4px 0;
  text-align: start;
`;

export const MemberListPopup: React.FC<MemberListPopupProps> = ({
  members
}) => {
  const sortedMembers = [...members].sort((a, b) => a.localeCompare(b));

  return (
    <PopupContainer>
      <MemberList>
        {sortedMembers.map((member, index) => (
          <MemberItem key={index}>{member}</MemberItem>
        ))}
      </MemberList>
    </PopupContainer>
  );
};
