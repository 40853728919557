import React from "react";
import styled from "styled-components";
import { iconNew, RotaBox, RotaPlaceholder } from "@teamrota/rota-design";

const { Checklist, Locked, Unlocked } = iconNew;

const StyledRotaBox = styled(RotaBox)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 32px;
  padding: 8px 32px;
  max-width: 964px;
  margin: auto;
`;

const Header = styled.span`
  font-weight: 500;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InfoWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SummaryLoader = () => {
  return (
    <Wrapper>
      <StyledRotaBox style={{ width: 200 }}>
        <InnerWrapper>
          <Checklist />
          <InfoWrapper>
            <Header>Open</Header>
            <RotaPlaceholder size={16} />
            <RotaPlaceholder size={16} />
          </InfoWrapper>
        </InnerWrapper>
      </StyledRotaBox>
      <StyledRotaBox style={{ width: 200 }}>
        <InnerWrapper>
          <Unlocked />
          <InfoWrapper>
            <Header>Approved</Header>
            <RotaPlaceholder size={16} />
          </InfoWrapper>
        </InnerWrapper>
      </StyledRotaBox>
      <StyledRotaBox style={{ width: 200 }}>
        <InnerWrapper>
          <Locked />
          <InfoWrapper>
            <Header>Locked</Header>
            <RotaPlaceholder size={16} />
          </InfoWrapper>
        </InnerWrapper>
      </StyledRotaBox>
    </Wrapper>
  );
};

interface Props {
  toDoCount: number;
  inProgressCount: number;
  approvedCount: number;
  lockedCount: number;
}

export const Summary = ({
  toDoCount,
  inProgressCount,
  approvedCount,
  lockedCount
}: Props) => {
  return (
    <Wrapper>
      <StyledRotaBox>
        <InnerWrapper>
          <Checklist />
          <InfoWrapper>
            <Header>Open</Header>
            <span>{`${toDoCount} timesheets to do`}</span>
            <span>{`${inProgressCount} timesheets in progress`}</span>
          </InfoWrapper>
        </InnerWrapper>
      </StyledRotaBox>
      <StyledRotaBox>
        <InnerWrapper>
          <Unlocked />
          <InfoWrapper>
            <Header>Approved</Header>
            <span>{`${approvedCount} timesheets approved`}</span>
          </InfoWrapper>
        </InnerWrapper>
      </StyledRotaBox>
      <StyledRotaBox>
        <InnerWrapper>
          <Locked />
          <InfoWrapper>
            <Header>Locked</Header>
            <span>{`${lockedCount} timesheets locked`}</span>
          </InfoWrapper>
        </InnerWrapper>
      </StyledRotaBox>
    </Wrapper>
  );
};
