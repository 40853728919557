import React from "react";
import styled from "styled-components";
import {
  RotaSearchInput,
  RotaCalendar,
  RotaPlaceholder
} from "@teamrota/rota-design";

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 32px;
`;

interface Props {
  dateRange?: [Date, Date];
  setRange: (values: [Date, Date]) => void;
  onSearch: (event: string) => void;
}

export const HeaderLoader = () => {
  return (
    <HeaderRow>
      <InnerWrapper style={{ gridTemplateColumns: "180px 180px" }}>
        <RotaPlaceholder size={32} />
        <RotaPlaceholder size={32} />
      </InnerWrapper>
    </HeaderRow>
  );
};

const Header = ({ dateRange, setRange, onSearch }: Props) => {
  return (
    <HeaderRow>
      <InnerWrapper>
        <RotaSearchInput onChange={onSearch} />
        <RotaCalendar
          isSelectsRange
          startDate={dateRange?.[0]}
          endDate={dateRange?.[1]}
          onChange={({
            startDate,
            endDate
          }: {
            startDate?: Date;
            endDate?: Date;
          }) => startDate && endDate && setRange([startDate, endDate])}
        />
      </InnerWrapper>
    </HeaderRow>
  );
};

export default Header;
