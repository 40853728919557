import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment-timezone";
import {
  iconNew,
  RotaTable,
  RotaButtonIcon,
  THead,
  TrHead,
  Th,
  TBody,
  Tr,
  Td,
  InnerCell,
  RotaCheckbox,
  RotaAvatar,
  RotaNewModal,
  ModalHeader,
  RotaStatusLabel,
  RotaPlaceholder,
  RotaTextarea,
  RotaInput,
  RotaDivider,
  useToast,
  RotaSelect,
  RotaButtonNew
} from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";
import { useSelectedItems } from "@teamrota/rota-design";
import { getFormatterMoney } from "~/src/utils/formatting";
import {
  TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS,
  TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS
} from "@teamrota/rota-common";
import useVenues from "./use-venues";

import { TimesheetBreakdownOptions } from "./timesheet-breakdown-options";
import styled from "styled-components";
import {
  OptionKey,
  BaseOptionKey,
  SleepOptionKey,
  TimesheetInternalStatus,
  optionTranslations,
  Subvenue
} from "./types";
import { Timesheet } from "./use-timesheets-with-stats";
import {
  useTimesheetBreakdown,
  MemberBreakdown
} from "./use-timesheet-breakdown";
import {
  InnerCellTime,
  SelectedItemsInfo,
  ActionsWrapper,
  RotaTableScrollable
} from "./style";
import {
  getMemberStatusType,
  getMemberPinIcon,
  memberStatusTranslations,
  convertMinutesToTimeString,
  convertHoursToTimeString,
  cancellationOptions,
  getCancellation,
  Cancellation
} from "./utils";
import { useUpdateTimesheetMember } from "./use-update-timesheet-member";

import {
  useTimesheetBreakdownCostDetails,
  TimesheetCostDetails
} from "./use-timesheet-breakdown-cost-details";
import { TIMESHEET_MEMBER_STATUS } from "@teamrota/rota-common";
import { useApproveTimesheetMembers } from "./use-approve-timesheet-members";
import { Currency } from "@teamrota/rota-common/dist/currency-support";
import { useDownloadTimesheetXlsx } from "./use-download-timesheet-xlsx";
import { TimesheetPdf } from "./timesheet-pdf";
import ScheduledHours from "./scheduled-hours";

const { OpenEye, ChevronLeft } = iconNew;
const Option = styled.option``;
const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 660px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
`;

interface Props {
  timesheet: Timesheet;
  onClose: () => void;
}

const getOptions = ({
  hasProviderSchedulerRole
}: {
  hasProviderSchedulerRole: boolean;
}) => {
  const optionalFields = (Object.keys(
    TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS
  ) as Array<BaseOptionKey>).filter(option =>
    hasProviderSchedulerRole
      ? true
      : !([
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PAY_RATE,
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_PAY
        ] as BaseOptionKey[]).includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS[option]
        )
  );

  const sleepTimeFields = Object.keys(
    TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS
  ) as Array<SleepOptionKey>;

  return [...optionalFields, ...sleepTimeFields];
};

const setStoredOptions = (optionsArray: string[]) =>
  (localStorage.breakdownOptions = JSON.stringify(optionsArray));

const getStoredOptions = (hasProviderSchedulerRole: boolean) => {
  const options: BaseOptionKey[] | null = JSON.parse(
    localStorage?.breakdownOptions || null
  );
  if (hasProviderSchedulerRole) return options;

  return options?.filter(
    option =>
      !([
        TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PAY_RATE,
        TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_PAY
      ] as BaseOptionKey[]).includes(
        TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS[option]
      )
  );
};

const Loader = () => {
  return (
    <RotaTable>
      <THead>
        <TrHead>
          <Th>
            <InnerCell>
              <RotaCheckbox isDisabled isChecked={false} onClick={() => {}} />
            </InnerCell>
          </Th>
          <Th />
          <Th style={{ textAlign: "left", minWidth: 266 }}>Name</Th>
          <Th style={{ minWidth: 180 }}>Feedback</Th>
          <Th>Role</Th>
          <Th>Scheduled hours</Th>
          <Th>In/Out</Th>
          <Th>Total hours</Th>
        </TrHead>
      </THead>
      <TBody>
        {Array.from({ length: 8 }).map((_, index) => {
          return (
            <Tr key={index}>
              <Td>
                <InnerCell>
                  <RotaCheckbox
                    isDisabled
                    isChecked={false}
                    onClick={() => {}}
                  />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder circle size={48} />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCellTime>
                  <RotaPlaceholder />
                </InnerCellTime>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
            </Tr>
          );
        })}
      </TBody>
    </RotaTable>
  );
};

// Default JS sorts uppercase > lowercase
// instead sort case insensitively
const sortAlphabetically = (a: Subvenue, b: Subvenue) => {
  if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
    return -1;
  }

  if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
    return 1;
  }

  return 0;
};

interface TimesheetBreakdownModalContentMemberProps {
  timesheetMember: MemberBreakdown;
  timesheet: Timesheet;
  selectedOptions: OptionKey[];
  currencyCode: string;
  isChecked: boolean;
  onToggleOneItem: (id: string) => void;
  refetchData: () => void;
}

const TimesheetBreakdownModalContentMember = ({
  timesheetMember,
  timesheet,
  selectedOptions,
  currencyCode,
  isChecked,
  onToggleOneItem,
  refetchData
}: TimesheetBreakdownModalContentMemberProps) => {
  const {
    id,
    memberId,
    name,
    memberAvatar,
    roleName,
    totalPay,
    totalCharge,
    payRate,
    chargeRate,
    status,
    feedbackForMember: initialFeedback,
    checkInTime,
    checkOutTime,
    totalMinutes,
    isNoShow,
    isTurnedAway,
    scheduledStartTime,
    scheduledEndTime,
    subvenueId,

    plannedSleepStartTime,
    plannedSleepEndTime,
    plannedSleepTimeMinutes,

    sleepStartTime,
    sleepEndTime,
    sleepTimeMinutes,

    disturbedHoursStart,
    disturbedHoursEnd,
    disturbedHoursMinutes
  } = timesheetMember;
  const { showToast } = useToast();
  const venues = useVenues();

  const subvenues =
    venues.find(venue => venue.id === timesheet?.venueId)?.subvenues || [];
  const sortedSubvenues = [...subvenues].sort(sortAlphabetically);
  const subvenueList = [{ id: -1, name: "None" }, ...sortedSubvenues];

  const [selectedSubvenueId, setSelectedSubvenueId] = useState(subvenueId);

  const checkIn = checkInTime ? moment(checkInTime).format("HH:mm") : "--:--";
  const checkOut = checkOutTime
    ? moment(checkOutTime).format("HH:mm")
    : "--:--";

  const plannedSleepStart = plannedSleepStartTime
    ? moment(plannedSleepStartTime).format("HH:mm")
    : "--:--";
  const plannedSleepEnd = plannedSleepEndTime
    ? moment(plannedSleepEndTime).format("HH:mm")
    : "--:--";
  const actualSleepStart = sleepStartTime
    ? moment(sleepStartTime).format("HH:mm")
    : "--:--";
  const actualSleepEnd = sleepEndTime
    ? moment(sleepEndTime).format("HH:mm")
    : "--:--";
  const disturbedSleepStart = disturbedHoursStart
    ? moment(disturbedHoursStart).format("HH:mm")
    : "--:--";
  const disturbedSleepEnd = disturbedHoursEnd
    ? moment(disturbedHoursEnd).format("HH:mm")
    : "--:--";

  const isShiftFinished = moment().isAfter(timesheet.shiftEndTime);
  const isLateCheckIn =
    !checkInTime ||
    moment(checkInTime).isAfter(timesheet.shiftStartTime, "minutes");

  const isLateCheckOut =
    !checkOutTime ||
    moment(checkOutTime).isAfter(timesheet.shiftEndTime, "minutes");

  const [feedbackForMember, setFeedbackForMember] = useState(
    initialFeedback || ""
  );
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>(
    timesheetMember.purchaseOrderNumber || ""
  );
  const [breakMinutes, setBreakMinutes] = useState<string>(
    timesheetMember.break.toString() || "0"
  );

  const formatterMoney = getFormatterMoney(currencyCode);
  const { updateTimesheetMember, loading } = useUpdateTimesheetMember({
    onCompleted: () => {
      refetchData();
      showToast("Timesheet member row was updated");
    }
  });

  const isDisabled =
    loading || timesheet.status === TimesheetInternalStatus.LOCKED;

  return (
    <>
      <Tr isActive={isChecked}>
        <Td>
          <InnerCell>
            <RotaCheckbox
              isChecked={isChecked}
              onClick={() => onToggleOneItem(memberId)}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaAvatar src={memberAvatar} />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell style={{ textAlign: "left" }}>{name}</InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaSelect
              disabled={timesheet.status === TimesheetInternalStatus.LOCKED}
              value={getCancellation({ isNoShow, isTurnedAway })}
              onChange={({ target: { value } }) => {
                updateTimesheetMember({
                  id: timesheetMember.id,
                  isNoShow: value === Cancellation.NO_SHOW,
                  isTurnedAway: value === Cancellation.TURNED_AWAY
                });
              }}
            >
              {cancellationOptions.map(option => (
                <Option value={option.value} key={option.value}>
                  {option.label}
                </Option>
              ))}
            </RotaSelect>
          </InnerCell>
        </Td>
        <Td>
          <InnerCell style={{ textAlign: "left" }}>
            <RotaTextarea
              placeholder="Feedback"
              value={feedbackForMember}
              onChange={e => setFeedbackForMember(e.target.value)}
              onBlur={() => {
                updateTimesheetMember({ id, feedbackForMember });
              }}
              isDisabled={isDisabled}
              wrapperStyles={{ margin: 0 }}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>{roleName}</InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <ScheduledHours
              startTime={scheduledStartTime}
              isDisabled={isDisabled}
              endTime={scheduledEndTime}
              onUpdateScheduledTime={({
                scheduledStartTime,
                scheduledEndTime
              }) =>
                updateTimesheetMember({
                  id,
                  scheduledStartTime,
                  scheduledEndTime
                })
              }
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCellTime>
            {getMemberPinIcon({
              status,
              isLateCheckIn,
              isLateCheckOut: isShiftFinished && isLateCheckOut,
              isChecked
            })}
            {`${checkIn} - ${checkOut}`}
          </InnerCellTime>
        </Td>
        <Td>
          <InnerCell>{convertMinutesToTimeString(totalMinutes)}</InnerCell>
        </Td>
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.STATUS
        ) && (
          <Td>
            <InnerCell>
              <RotaStatusLabel type={getMemberStatusType(status)}>
                {memberStatusTranslations[status]}
              </RotaStatusLabel>
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes("SUBVENUE") && (
          <Td>
            <InnerCell>
              <RotaSelect
                disabled={timesheet.status === TimesheetInternalStatus.LOCKED}
                value={selectedSubvenueId}
                onChange={({ target: { value } }) => {
                  console.log({ value });
                  updateTimesheetMember({
                    id: timesheetMember.id,
                    subvenueId: Number(value)
                  });
                  setSelectedSubvenueId(value);
                }}
              >
                {subvenueList.map(subvenue => (
                  <Option value={subvenue.id} key={subvenue.id}>
                    {subvenue.name}
                  </Option>
                ))}
              </RotaSelect>
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.BREAK
        ) && (
          <Td>
            <InnerCell>
              <RotaInput
                placeholder="Break"
                isDisabled={isDisabled}
                value={breakMinutes}
                onChange={e => setBreakMinutes(e.target.value)}
                saveOnBlur={({ value }) => {
                  updateTimesheetMember({
                    id,
                    breakMinutes: value
                  });
                }}
              />
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_PAY
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(totalPay / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PAY_RATE
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(payRate / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.CHARGE_RATE
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(chargeRate / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_CHARGE
        ) && (
          <Td>
            <InnerCell>
              {timesheet.isUncalculatedRole
                ? "N/A"
                : formatterMoney.format(totalCharge / 100)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PURCHASE_ORDER_NUMBER
        ) && (
          <Td>
            <InnerCell>
              <RotaInput
                placeholder="1231231"
                isDisabled={isDisabled}
                value={purchaseOrderNumber}
                onChange={e => setPurchaseOrderNumber(e.target.value)}
                saveOnBlur={({ value }) => {
                  updateTimesheetMember({
                    id,
                    purchaseOrderNumber: value
                  });
                }}
              />
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.PLANNED_SLEEP_TIME
        ) && (
          <Td>
            <InnerCell>{`${plannedSleepStart} - ${plannedSleepEnd}`}</InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.PLANNED_SLEEP_TIME_TOTAL
        ) && (
          <Td>
            <InnerCell>
              {convertMinutesToTimeString(plannedSleepTimeMinutes)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.ACTUAL_SLEEP_TIME
        ) && (
          <Td>
            <InnerCell>{`${actualSleepStart} - ${actualSleepEnd}`}</InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.ACTUAL_SLEEP_TIME_TOTAL
        ) && (
          <Td>
            <InnerCell>
              {convertMinutesToTimeString(sleepTimeMinutes)}
            </InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.DISTURBED_SLEEP_TIME
        ) && (
          <Td>
            <InnerCell>{`${disturbedSleepStart} - ${disturbedSleepEnd}`}</InnerCell>
          </Td>
        )}
        {selectedOptions.includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS.DISTURBED_SLEEP_TIME_TOTAL
        ) && (
          <Td>
            <InnerCell>
              {convertMinutesToTimeString(disturbedHoursMinutes)}
            </InnerCell>
          </Td>
        )}
      </Tr>
    </>
  );
};

const CostDetailsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const CostDetailsLine = styled.p`
  font-size: 16px;
  font-weight: 500;
  & span {
    font-weight: 700;
  }
`;

const SummaryLine = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.primary.main};
  & span {
    margin-left: 98px;
    color: ${({ theme }) => theme.text.primary};
  }
`;

const TimesheetCostDetailsSummary = ({
  isUncalculatedRole,
  timesheetCostDetails,
  currencyCode
}: {
  isUncalculatedRole: boolean;
  timesheetCostDetails: TimesheetCostDetails;
  currencyCode: Currency;
}) => {
  const formatterMoney = getFormatterMoney(currencyCode);

  const {
    totalRegularWorkedHours,
    chargeRate,
    totalCharge,
    totalBonusAmount,
    totalVat,
    totalWithVat,
    totalOverHours,
    totalOverHourAmount,
    overstayChargeRate,
    overtimeChargeRate
  } = timesheetCostDetails;

  if (isUncalculatedRole) {
    return (
      <CostDetailsWrapper>
        <CostDetailsLine>
          {`Total Working Hours = `}
          <span>{convertHoursToTimeString(totalRegularWorkedHours)}</span>
        </CostDetailsLine>
        <CostDetailsLine>
          <span>
            Pay for this shift will be calculated in the Payroll download
          </span>
        </CostDetailsLine>
      </CostDetailsWrapper>
    );
  }

  return (
    <CostDetailsWrapper>
      <CostDetailsLine>
        {`${convertHoursToTimeString(
          totalRegularWorkedHours
        )} x ${formatterMoney.format(chargeRate / 100)} = `}
        <span>{formatterMoney.format(totalCharge / 100)}</span>
      </CostDetailsLine>
      <CostDetailsLine>
        {`${convertHoursToTimeString(totalOverHours)} x ${formatterMoney.format(
          (overstayChargeRate || overtimeChargeRate) / 100
        )} = `}
        <span>{formatterMoney.format(totalOverHourAmount / 100)}</span>
      </CostDetailsLine>
      <CostDetailsLine>
        {`Total Bonus = `}
        <span>{formatterMoney.format(totalBonusAmount / 100)}</span>
      </CostDetailsLine>
      <CostDetailsLine>
        {`VAT (20%) = `}
        <span>{formatterMoney.format(totalVat / 100)}</span>
      </CostDetailsLine>
      <RotaDivider />
      <SummaryLine>
        {`Total: `}
        <span>{formatterMoney.format(totalWithVat / 100)}</span>
      </SummaryLine>
    </CostDetailsWrapper>
  );
};

interface TimesheetBreakdownModalContentProps {
  timesheet: Timesheet;
  selectedOptions: OptionKey[];
  options: OptionKey[];
}

const TimesheetBreakdownModalContent = ({
  timesheet,
  selectedOptions,
  options
}: TimesheetBreakdownModalContentProps) => {
  const { showToast } = useToast();
  const {
    downloadTimesheetXlsx,
    loading: loadingDownload
  } = useDownloadTimesheetXlsx();

  const {
    timesheetMembers,
    currencyCode,
    loading,
    refetch
  } = useTimesheetBreakdown({
    id: timesheet.id
  });

  const {
    timesheetCostDetails,
    loading: loadingTimesheetCostDetails,
    refetch: refetchTimesheetCostDetails
  } = useTimesheetBreakdownCostDetails({
    id: timesheet.id
  });

  const {
    selectedItemsArray,
    hasSelectedAll,
    onToggleOneItem,
    onToggleAllItems,
    reset
  } = useSelectedItems(timesheetMembers.map(({ memberId }) => memberId));

  const { approveTimesheetMembers } = useApproveTimesheetMembers({
    onCompleted: approvedCount => {
      showToast(
        `${approvedCount} ${
          approvedCount === 1 ? "Member" : "Members"
        } approved`
      );
      reset();
    }
  });

  if (loading && !timesheetMembers) return <Loader />;

  const timesheetMemberRowIds = selectedItemsArray.length
    ? selectedItemsArray.map(
        id => timesheetMembers.find(({ memberId }) => id === memberId)?.id
      )
    : timesheetMembers.map(({ id }) => id);

  const disableApproveButton =
    [TimesheetInternalStatus.LOCKED, TimesheetInternalStatus.APPROVED].includes(
      timesheet.status
    ) ||
    !selectedItemsArray.length ||
    !selectedItemsArray.some(
      id =>
        timesheetMembers.find(({ memberId }) => memberId === id)?.status !==
        TIMESHEET_MEMBER_STATUS.APPROVED
    );

  const pdfReady =
    timesheet &&
    timesheetMembers &&
    !(loadingTimesheetCostDetails && !timesheetCostDetails);

  return (
    <>
      <ActionsWrapper>
        <RotaButtonNew
          disabled={disableApproveButton}
          onClick={() => {
            approveTimesheetMembers({
              memberIds: selectedItemsArray,
              timesheetId: timesheet.id
            });
          }}
        >
          Approve
        </RotaButtonNew>
        {!!selectedItemsArray.length && (
          <SelectedItemsInfo>{`Selected ${selectedItemsArray.length} timesheets`}</SelectedItemsInfo>
        )}
      </ActionsWrapper>
      <div style={{ overflow: "auto", flexGrow: 1 }}>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox
                    isChecked={hasSelectedAll}
                    onClick={() => onToggleAllItems()}
                  />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", minWidth: 266 }}>Name</Th>
              <Th style={{ textAlign: "left", minWidth: 200 }}>Cancellation</Th>
              <Th style={{ minWidth: 180 }}>Feedback</Th>
              <Th style={{ minWidth: 180 }}>Role</Th>
              <Th style={{ minWidth: 180 }}>Scheduled hours</Th>
              <Th style={{ minWidth: 180 }}>In/Out</Th>
              <Th>Total hours</Th>
              {options.map(option => {
                if (!selectedOptions.includes(option)) return null;
                return (
                  <Th style={{ minWidth: 180 }} key={option}>
                    {optionTranslations[option]}
                  </Th>
                );
              })}
            </TrHead>
          </THead>
          <TBody>
            {timesheetMembers.map(timesheetMember => {
              return (
                <TimesheetBreakdownModalContentMember
                  key={timesheetMember.id}
                  timesheetMember={timesheetMember}
                  timesheet={timesheet}
                  currencyCode={currencyCode}
                  isChecked={selectedItemsArray.includes(
                    timesheetMember.memberId
                  )}
                  onToggleOneItem={onToggleOneItem}
                  selectedOptions={selectedOptions}
                  refetchData={() => {
                    refetch();
                    refetchTimesheetCostDetails();
                  }}
                />
              );
            })}
          </TBody>
        </RotaTableScrollable>
      </div>
      {!(loadingTimesheetCostDetails && !timesheetCostDetails) && (
        <TimesheetCostDetailsSummary
          isUncalculatedRole={timesheet.isUncalculatedRole}
          timesheetCostDetails={timesheetCostDetails}
          currencyCode={currencyCode}
        />
      )}
      <ButtonsWrapper>
        {pdfReady ? (
          <PDFDownloadLink
            document={
              <TimesheetPdf
                timesheet={timesheet}
                timesheetMembers={timesheetMembers}
                timesheetCostDetails={timesheetCostDetails}
                currencyCode={currencyCode}
              />
            }
            fileName={`timesheet_${timesheet.id}.pdf`}
          >
            {({ loading }: { loading: boolean }) => (
              <RotaButtonNew disabled={loading} isLoading={loading}>
                PDF
              </RotaButtonNew>
            )}
          </PDFDownloadLink>
        ) : (
          <RotaButtonNew disabled>PDF</RotaButtonNew>
        )}

        <RotaButtonNew
          isLoading={loadingDownload}
          onClick={() =>
            downloadTimesheetXlsx({
              timesheetId: timesheet.id,
              timesheetMemberRowIds: timesheetMemberRowIds.join(","),
              fields: selectedOptions.join(",")
            })
          }
        >
          Spreadsheet
        </RotaButtonNew>
      </ButtonsWrapper>
    </>
  );
};

export const TimesheetBreakdownModal = ({ timesheet, onClose }: Props) => {
  const auth = useAuth();
  const hasProviderSchedulerRole = auth.hasRole(Role.PROVIDER_SCHEDULER);

  const DEFAULT_OPTIONS = getOptions({
    hasProviderSchedulerRole
  });

  const [isShowBreakdownOptions, setIsShowBreakdownOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionKey[]>(
    getStoredOptions(hasProviderSchedulerRole) || DEFAULT_OPTIONS
  );

  const handleOptionChange = (option: OptionKey) => {
    const newOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(id => id !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newOptions);
    setStoredOptions(newOptions);
  };

  const isSameDay = moment(timesheet.shiftStartTime).isSame(
    timesheet.shiftEndTime,
    "day"
  );

  const timesheetDate = isSameDay
    ? moment(timesheet.shiftStartTime).format("Do MMM YYYY")
    : `${moment(timesheet.shiftStartTime).format("Do MMM YYYY")} -
      ${moment(timesheet.shiftEndTime).format("Do MMM YYYY")}`;

  return (
    <RotaNewModal
      onClose={onClose}
      header={
        <ModalHeader
          title={timesheet.shiftName || `Shift at ${timesheet.venueName}`}
          subtitle={timesheetDate}
          startAction={
            <RotaButtonIcon onClick={onClose}>
              <ChevronLeft />
            </RotaButtonIcon>
          }
          endAction={
            <OptionsButton
              onClick={() => setIsShowBreakdownOptions(!isShowBreakdownOptions)}
            >
              <OpenEye />
              {isShowBreakdownOptions && (
                <TimesheetBreakdownOptions
                  selectedOptions={selectedOptions}
                  handleOptionChange={handleOptionChange}
                  options={getOptions({
                    hasProviderSchedulerRole
                  })}
                />
              )}
            </OptionsButton>
          }
        />
      }
    >
      <ModalContentWrapper>
        <TimesheetBreakdownModalContent
          timesheet={timesheet}
          selectedOptions={selectedOptions}
          options={DEFAULT_OPTIONS}
        />
      </ModalContentWrapper>
    </RotaNewModal>
  );
};

export default TimesheetBreakdownModal;
