import React, { ComponentPropsWithoutRef, useState } from "react";

import moment from "moment-timezone";
import styled from "styled-components";

import {
  iconNew,
  RotaButtonIcon,
  Tr,
  Td,
  InnerCell,
  RotaCheckbox,
  RotaAvatar,
  RotaInput,
  RotaBadge,
  RotaPlaceholder,
  useToast
} from "@teamrota/rota-design";

import { getFormatterMoney } from "~/src/utils/formatting";

import { MemberListPopup } from "./member-list-popup";
import { useUpdateTimesheet } from "./use-update-timesheet";
import { TimesheetInternalStatus } from "./types";
import { Timesheet } from "./use-timesheets-with-stats";
import { convertMinutesToTimeString } from "./utils";

const { Checklist, Unlocked, Locked, ChevronRight } = iconNew;

const ShiftName = styled.div`
  font-weight: 500;
`;

const ShiftTime = styled.div``;

export const TimesheetRowLoader = () => {
  return (
    <Tr>
      <Td>
        <InnerCell>
          <RotaCheckbox isChecked={false} onClick={() => {}} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder circle size={48} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell style={{ textAlign: "left" }}>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder circle size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaButtonIcon onClick={() => {}}>
            <ChevronRight />
          </RotaButtonIcon>
        </InnerCell>
      </Td>
    </Tr>
  );
};

interface Props extends ComponentPropsWithoutRef<"div"> {
  timesheet: Timesheet;
  logo?: string;
  currencyCode: string;
  isChecked: boolean;
  onToggle: () => void;
  setExpandedTimesheet: (timesheet: Timesheet) => void;
  refetch: () => void;
}

export const TimesheetRow = ({
  timesheet,
  logo,
  currencyCode,
  isChecked,
  onToggle,
  setExpandedTimesheet,
  refetch,
  ...props
}: Props) => {
  const { showToast } = useToast();
  const formatterMoney = getFormatterMoney(currencyCode);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>(
    timesheet.purchaseOrderNumber || ""
  );
  const [showMemberPopup, setShowMemberPopup] = useState(false);

  const { updateTimesheet, loading } = useUpdateTimesheet({
    onCompleted: () => {
      showToast("Timesheet was updated");
      refetch();
    }
  });

  const getBadge = (status: TimesheetInternalStatus) => {
    switch (status) {
      case TimesheetInternalStatus.APPROVED:
        return (
          <RotaBadge type="success">
            <Unlocked />
          </RotaBadge>
        );
      case TimesheetInternalStatus.IN_PROGRESS:
        return (
          <RotaBadge type="warning">
            <Checklist />
          </RotaBadge>
        );
      case TimesheetInternalStatus.LOCKED:
        return (
          <RotaBadge type="error">
            <Locked />
          </RotaBadge>
        );
      case TimesheetInternalStatus.TO_DO:
      default:
        return (
          <RotaBadge type="warning">
            <Checklist />
          </RotaBadge>
        );
    }
  };

  const isSameDay = moment(timesheet.shiftStartTime).isSame(
    timesheet.shiftEndTime,
    "day"
  );

  const formattedStart = moment(timesheet.shiftStartTime).format("Do MMM YYYY");
  const formattedEnd = moment(timesheet.shiftEndTime).format("Do MMM YYYY");

  return (
    <>
      <Tr isActive={isChecked} style={{ zIndex: "unset" }}>
        <Td>
          <InnerCell>
            <RotaCheckbox
              isChecked={isChecked}
              isDisabled={
                timesheet.isPreview ||
                [
                  TimesheetInternalStatus.APPROVED,
                  TimesheetInternalStatus.LOCKED
                ].includes(timesheet.status)
              }
              onClick={onToggle}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaAvatar src={logo} />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell style={{ textAlign: "left" }}>
            <ShiftName>
              {timesheet.shiftName || `Shift at ${timesheet.venueName}`}
            </ShiftName>
            <ShiftTime>{`${moment(timesheet.shiftStartTime).format(
              "HH:mm"
            )} - ${moment(timesheet.shiftEndTime).format("HH:mm")}`}</ShiftTime>
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>{timesheet.shiftId}</InnerCell>
        </Td>
        <Td>
          <InnerCell>
            {isSameDay ? (
              formattedStart
            ) : (
              <>
                {formattedStart} -
                <br /> {formattedEnd}
              </>
            )}
          </InnerCell>
        </Td>
        <Td>
          <InnerCell
            onMouseEnter={() => setShowMemberPopup(true)}
            onMouseLeave={() => setShowMemberPopup(false)}
            style={{
              cursor: "pointer",
              position: "relative",
              background: showMemberPopup ? "#e6e7e9" : "transparent",
              borderRadius: "4px",
              zIndex: showMemberPopup ? 1000 : 0
            }}
          >
            {timesheet.peopleCount}
            {showMemberPopup && timesheet.memberNames && (
              <MemberListPopup members={timesheet.memberNames} />
            )}
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>{convertMinutesToTimeString(timesheet.time)}</InnerCell>
        </Td>
        <Td>
          <InnerCell>
            {timesheet.isPreview
              ? "N/A"
              : timesheet.isUncalculatedRole
              ? "Uncalculated"
              : formatterMoney.format(timesheet.cost / 100)}
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaInput
              wrapperStyles={{ margin: 0 }}
              placeholder="1231231"
              isDisabled={
                loading ||
                [
                  TimesheetInternalStatus.LOCKED,
                  TimesheetInternalStatus.APPROVED
                ].includes(timesheet.status) ||
                timesheet.isPreview
              }
              value={purchaseOrderNumber}
              onChange={e => setPurchaseOrderNumber(e.target.value)}
              saveOnBlur={({ value }) => {
                updateTimesheet({
                  id: timesheet.id,
                  purchaseOrderNumber: value
                });
              }}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>{getBadge(timesheet.status)}</InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaButtonIcon onClick={() => setExpandedTimesheet(timesheet)}>
              <ChevronRight />
            </RotaButtonIcon>
          </InnerCell>
        </Td>
      </Tr>
    </>
  );
};
