import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useToast } from "@teamrota/rota-design";
import { useCallback } from "react";

const TIMESHEET_MEMBER_UPDATE = gql`
  mutation updateTimesheetMember(
    $id: ID!
    $ratingOfMember: Int
    $feedbackForMember: String
    $blockMember: Boolean
    $purchaseOrderNumber: String
    $breakMinutes: Int
    $isNoShow: Boolean
    $isTurnedAway: Boolean
    $scheduledStartTime: String
    $scheduledEndTime: String
    $subvenueId: Int
  ) {
    updateTimesheetMember(
      id: $id
      ratingOfMember: $ratingOfMember
      feedbackForMember: $feedbackForMember
      blockMember: $blockMember
      purchaseOrderNumber: $purchaseOrderNumber
      breakMinutes: $breakMinutes
      isNoShow: $isNoShow
      isTurnedAway: $isTurnedAway
      scheduledStartTime: $scheduledStartTime
      scheduledEndTime: $scheduledEndTime
      subvenueId: $subvenueId
    ) {
      id
      ratingOfMember
      feedbackForMember
      purchaseOrderNumber
      isNoShow
      isTurnedAway
      scheduledStartTime
      scheduledEndTime
      subvenueId
    }
  }
`;

interface Params {
  onCompleted?: () => void;
}

interface UpdateParams {
  id: string;
  ratingOfMember?: number;
  feedbackForMember?: string;
  blockMember?: boolean;
  purchaseOrderNumber?: string;
  breakMinutes?: string;
  isNoShow?: boolean;
  isTurnedAway?: boolean;
  scheduledStartTime?: string;
  scheduledEndTime?: string;
  subvenueId?: number;
}

export const useUpdateTimesheetMember = ({ onCompleted }: Params) => {
  const { showToast } = useToast();
  const [update, { loading }] = useMutation(TIMESHEET_MEMBER_UPDATE, {
    onError: error => {
      showToast(error.message, { severity: "error" });
    },
    onCompleted,
    refetchQueries: ["TimesheetsSummary"]
  });

  const updateTimesheetMember = useCallback(
    async ({
      id,
      ratingOfMember,
      feedbackForMember,
      blockMember,
      breakMinutes,
      purchaseOrderNumber,
      isNoShow,
      isTurnedAway,
      scheduledStartTime,
      scheduledEndTime,
      subvenueId
    }: UpdateParams) => {
      await update({
        variables: {
          id,
          ratingOfMember,
          feedbackForMember,
          blockMember,
          breakMinutes,
          purchaseOrderNumber,
          isNoShow,
          isTurnedAway,
          scheduledStartTime,
          scheduledEndTime,
          subvenueId
        }
      });
    },
    [update]
  );

  return {
    updateTimesheetMember,
    loading
  };
};
