import React, { useState } from "react";
import moment from "moment-timezone";
import {
  ModalHeader,
  RotaCalendar,
  RotaDropdown,
  RotaNewModal,
  RotaSwitchWithLabel,
  iconNew
} from "@teamrota/rota-design";

import { SHIFT_TYPES } from "~/src/consts";
import { ATTRIBUTE_OPTIONS, RATE_SHOW_OPTIONS } from "../consts";

import { makeTable } from "../util";

import RuleDescription from "../RuleDescription";
import RuleName from "../RuleName";

import { usePayRulesInfo } from "./graphql/use-pay-rules-info";

import {
  DropDownContainer,
  SwitchContainer,
  InputRow,
  OptionsButton,
  Placeholder,
  ContentArea,
  Key,
  KeyTitle
} from "./styles";

const { Sun, Moon, Cancel } = iconNew;

interface RulesInfoModalProps {
  id: string;
  payrollRateCards: any[];
  onClose: () => void;
}

const RulesInfoModal: React.FC<RulesInfoModalProps> = ({
  id,
  payrollRateCards,
  onClose
}) => {
  const [start, setStart] = useState<Date>(moment().toDate());
  const [rateType, setRateType] = useState<string>(RATE_SHOW_OPTIONS[0].value);
  const [shiftType, setShiftType] = useState<string>("DAY");
  const [attribute, setAttribute] = useState<string | undefined>(undefined);

  const startWeek = moment(start)
    .startOf("week")
    .add(1, "days")
    .toDate();

  const endWeek = moment(startWeek)
    .add(7, "days")
    .toDate();

  const { entries, loading } = usePayRulesInfo({
    id,
    shiftType,
    attribute,
    start: startWeek,
    end: endWeek
  });

  const table = makeTable(entries);

  return (
    <RotaNewModal
      onClose={onClose}
      header={
        <ModalHeader
          title={"Rules Info"}
          endAction={
            <OptionsButton onClick={onClose}>
              <Cancel />
            </OptionsButton>
          }
        />
      }
    >
      <RuleDescription id={id} payrollRateCards={payrollRateCards} />

      <InputRow>
        <DropDownContainer width={300}>
          &nbsp;
          <RotaCalendar
            selectedDate={start}
            onChange={(values: { date?: Date }) =>
              values?.date && setStart(values?.date)
            }
            minDate={moment().toDate()}
            maxDate={moment()
              .add(1, "years")
              .toDate()}
            disabled={false}
          />
        </DropDownContainer>

        <SwitchContainer width={210}>
          <RotaSwitchWithLabel
            iconOn={<Sun />}
            iconOff={<Moon />}
            checked={shiftType === SHIFT_TYPES.DAY}
            onChange={e =>
              setShiftType(
                e.target.checked ? SHIFT_TYPES.DAY : SHIFT_TYPES.NIGHT
              )
            }
          >
            Shift Type
          </RotaSwitchWithLabel>
        </SwitchContainer>

        <DropDownContainer width={250}>
          <RotaDropdown
            id="attribute"
            label={"Attribute"}
            placeholder="Choose..."
            options={ATTRIBUTE_OPTIONS}
            value={attribute}
            onChange={value => setAttribute(value)}
            width="120px"
            isError={false}
            errorMessage={""}
          />
        </DropDownContainer>
        <DropDownContainer width={250}>
          <RotaDropdown
            id="rateType"
            label={"Showing"}
            placeholder="Choose..."
            options={RATE_SHOW_OPTIONS}
            value={rateType}
            onChange={value => setRateType(value)}
            width="120px"
            isError={false}
            errorMessage={""}
          />
        </DropDownContainer>

        <Key>
          <KeyTitle>Key:</KeyTitle>
          <RuleName name="Definitely applies" />
          <RuleName name="Possibly applies" isPossible />
        </Key>
      </InputRow>

      <ContentArea>
        <Placeholder isShow={!entries && !loading}>
          Please choose options
        </Placeholder>

        <Placeholder isShow={loading}>Loading...</Placeholder>

        {table && (
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                {Array.from(Array(7).keys()).map(i => {
                  const date = moment(startWeek).add(i, "days");
                  return <th>{date.format("ddd DD/MM")}</th>;
                })}
              </tr>
            </thead>

            {table.map((row, i) => {
              return (
                <tr key={i}>
                  <th>{row[0].time}</th>
                  {row.map(cell => (
                    <>
                      {cell.info && (
                        <td rowSpan={cell.rowSpan} colSpan={cell.colSpan}>
                          {rateType === RATE_SHOW_OPTIONS[0].value && (
                            <>
                              {cell.info.definiteRule?.name && (
                                <>
                                  <RuleName
                                    name={cell.info.definiteRule?.name}
                                    id={id}
                                    payrollRateCards={payrollRateCards}
                                    color={cell.color}
                                  />
                                  <br />
                                </>
                              )}

                              {cell.info.possibleRules.map((rule: any) => (
                                <RuleName
                                  name={rule.name}
                                  id={id}
                                  payrollRateCards={payrollRateCards}
                                  isPossible
                                  color={cell.color}
                                >
                                  {rule.name}
                                </RuleName>
                              ))}
                            </>
                          )}

                          {rateType !== RATE_SHOW_OPTIONS[0].value && (
                            <>
                              {cell.info.definiteRates[rateType]?.length >
                                0 && (
                                <>
                                  {cell.info.definiteRates[rateType].map(
                                    (rate: any) => (
                                      <RuleName
                                        name={rate}
                                        isRate
                                        id={id}
                                        payrollRateCards={payrollRateCards}
                                        color={cell.color}
                                      />
                                    )
                                  )}
                                  <br />
                                </>
                              )}

                              {cell.info.possibleRates[rateType].map(
                                (rate: any) => (
                                  <RuleName
                                    name={rate}
                                    isRate
                                    isPossible
                                    color={cell.color}
                                    id={id}
                                    payrollRateCards={payrollRateCards}
                                  />
                                )
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </>
                  ))}
                </tr>
              );
            })}
          </table>
        )}
      </ContentArea>
    </RotaNewModal>
  );
};

export default RulesInfoModal;
