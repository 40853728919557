import moment from "moment-timezone";

export const getDefaultShiftStartTime = selectedDay => {
  const result = moment(selectedDay);
  const now = moment.tz();

  result.hour(now.hour());
  result.minute(now.minute() + 15);
  result.second(0);
  result.millisecond(0);

  return result;
};
