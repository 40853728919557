import { gql } from "@apollo/client";

export const TimesheetFragment = gql`
  fragment TimesheetFragment on TimesheetRecord {
    id
    shiftName
    shiftId
    peopleCount
    time
    cost
    purchaseOrderNumber
    status
    shiftStartTime
    shiftEndTime
    venueId
    venueName
    venueAddress
    isUncalculatedRole
    providerName
    numberRequested
    subvenueId
    subvenueName
    memberNames
  }
`;
